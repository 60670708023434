import { Box, TextField, generateUtilityClasses, styled } from '@mui/material';
import { rem } from '@otello/helpers';

export const classes = generateUtilityClasses('Feedback', [
  'boxRating',
  'textField',
]);

export const BoxRating = styled(Box, {
  label: classes.boxRating,
  shouldForwardProp: (props) => props !== 'isEmpty',
})<{ isEmpty?: boolean }>(({ theme: { palette }, isEmpty }) => ({
  color: isEmpty ? palette.basic.tertiary : palette.basic.green['90'],
  width: rem(56),
  height: rem(56),
  borderRadius: rem(12),
  backgroundColor: palette.basic.gray['20'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const TextFieldStyled = styled(TextField, {
  label: classes.textField,
})(({ theme: { palette } }) => ({
  '& .MuiOutlinedInput-root': {
    fieldset: {
      borderColor: palette.basic.gray['50'],
    },
    '&:hover fieldset': {
      borderColor: palette.basic.gray['70'],
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px',
      borderColor: palette.basic.green['90'],
    },
    '&.Mui-error fieldset': {
      borderColor: palette.basic.red['100'],
    },

    '&.Mui-error:hover fieldset': {
      border: `1px solid ${palette.basic.red['100']}`,
    },

    '&.Mui-error.Mui-focused fieldset': {
      border: `1px solid ${palette.basic.red['100']}`,
    },
  },
  '.MuiInputBase-input': {
    fontSize: rem(16),
    fontWeight: 400,
    lineHeight: rem(20),
  },
  '.MuiInputBase-input::placeholder': {
    fontSize: rem(16),
    fontWeight: 400,
  },
  '& .MuiFormHelperText-root.Mui-error': {
    margin: rem(6),
    fontSize: rem(14),
    lineHeight: rem(18),
    color: palette.basic.red['100'],
  },
}));
