import { Box, ListItemIcon, Skeleton, Typography } from '@mui/material';
import { pages, rem } from '@otello/helpers';
import { useAppSelector } from '@otello/store';
import { NavLink } from 'react-router-dom';

import { NavLinkBase, Wrapper } from '../../Header.styles';
import { navRouter } from '../../helpers/helpers';

export const NavigationDesktop = () => {
  const active = useAppSelector((state) => state.hotel.active);

  const isExtranet = active?.is_extranet;

  return (
    <Wrapper component="nav" justifyContent="space-between">
      {navRouter({ isMobile: false, isExtranet: !!active?.is_extranet }).map(
        ({
          path,
          name,
          icon,
          loadablePages,
          attributeValue,
          //TODO: временный флаг, когда будет неактуален ts-ignore не понадобится
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          isInProgress = false,
        }) => (
          <Box key={path} position="relative">
            {!active ? (
              <Skeleton
                variant="rounded"
                animation="wave"
                height={rem(26)}
                width={rem(150)}
                sx={{ flexShrink: 0, borderRadius: rem(20) }}
              />
            ) : (
              <NavLinkBase
                data-cy={`${attributeValue}_link`}
                component={NavLink}
                to={path}
                onMouseOver={() => {
                  loadablePages.preload();
                }}
                sx={{
                  padding: rem(isInProgress ? rem(10) : rem(6, 16, 6, 10)),
                }}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                {name}

                {!isExtranet && pages.ROOMS === path && (
                  <Typography variant="p3" ml={rem(8)}>
                    {active?.amount.roomTypes}
                  </Typography>
                )}

                {!isExtranet && pages.RATES === path && (
                  <Typography variant="p3" ml={rem(8)}>
                    {active?.amount.rates}
                  </Typography>
                )}

                <Box
                  position="absolute"
                  width="100%"
                  textAlign="center"
                  color="basic.red.80"
                  bottom={rem(-1)}
                >
                  {isInProgress && (
                    <Typography variant="caption">в разработке</Typography>
                  )}
                </Box>
              </NavLinkBase>
            )}
          </Box>
        ),
      )}
    </Wrapper>
  );
};
