import { generateUtilityClasses, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { rem } from '@otello/helpers';
import { BREAKPOINTS } from '@otello/theme';

export const classes = generateUtilityClasses('Footer', ['root']);

export const Root = styled(Box, {
  label: classes.root,
  shouldForwardProp: (props) => props !== 'isPrices',
})<{ isPrices: boolean }>(({ theme: { palette, breakpoints }, isPrices }) => ({
  flexShrink: 0,
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: palette.basic.brown['80'],

  [breakpoints.up(BREAKPOINTS.MD)]: {
    height: rem(isPrices ? 62 : 118),
  },
}));
