import { FC, ReactElement } from 'react';

import { Box, Typography } from '@mui/material';
import { PaperProps } from '@mui/material/Paper/Paper';
import { SadSmile } from '@otello/assets';
import { rem } from '@otello/helpers';

import { PaperBase } from '../../../PaperBase/PaperBase';

interface ErrorBlockProps extends PaperProps {
  children?: ReactElement;
  title?: PaperProps['title'];
}

export const ErrorBlock: FC<ErrorBlockProps> = ({
  children,
  title,
  ...rest
}) => {
  return (
    <PaperBase title={title} {...rest}>
      <Box m="auto">
        <Box display="flex" justifyContent="center">
          <SadSmile />
        </Box>
        <Typography variant="h4" mt={rem(24)}>
          Что-то пошло не так!
        </Typography>
      </Box>
    </PaperBase>
  );
};
