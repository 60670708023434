import { FC } from 'react';

import { Box, Grid, Link, Typography } from '@mui/material';
import { Telegram } from '@otello/assets';
import { feedback_url, rem } from '@otello/helpers';

import { ContactTypes } from '../../helpers/types';

interface ContactProps {
  title: string;
  contact: string;
  type: ContactTypes;
}

export const Contact: FC<ContactProps> = ({ title, contact, type }) => {
  const actualHref =
    type === ContactTypes.PHONE
      ? `tel:${contact}`
      : type === ContactTypes.MAIL
      ? `mailto:${contact}`
      : feedback_url;

  return (
    <Grid item p={rem(10, 0)} display="flex" justifyContent="space-between">
      <Box display="flex" flexDirection="column" ml={rem(8)}>
        <Typography variant="caption" color="basic.secondary">
          {title}
        </Typography>
        <Typography variant="footnote" color="basic.primary">
          <Link
            target="_blank"
            rel="noopener,noreferrer"
            data-cy={`support_${type}_link`}
            href={actualHref}
            variant="footnote"
            color="basic.primary"
            underline="hover"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: 'basic.green.90',
              },
            }}
          >
            {contact}
          </Link>
        </Typography>
      </Box>
      {type === ContactTypes.TELEGRAM && (
        <Box display="flex" alignItems="center">
          <Link
            target="_blank"
            rel="noopener,noreferrer"
            data-cy="support_telegram_link"
            href={actualHref}
            variant="footnote"
            color="basic.tertiary"
            sx={{
              display: 'flex',
              cursor: 'pointer',
              '&:hover': {
                color: 'basic.green.90',
              },
            }}
          >
            <Telegram width={rem(24)} height={rem(24)} />
          </Link>
        </Box>
      )}
    </Grid>
  );
};
