import {
  useState,
  MouseEvent,
  useEffect,
  useMemo,
  useLayoutEffect,
  ReactNode,
  forwardRef,
} from 'react';

import { Box, ListProps, Menu, Typography, useMediaQuery } from '@mui/material';
import { SxProps } from '@mui/system';
import { Dropdown } from '@otello/assets';
import { rem } from '@otello/helpers';
import { BREAKPOINTS, theme } from '@otello/theme';

import { ButtonBase } from '../../buttons';
import { DataLoader } from '../../DataLoader/DataLoader';
import { SwipeableDrawer } from '../../SwipeableDrawer/SwipeableDrawer';
import { SubHeaderInput } from '../components/SubHeaderInput/SubHeaderInput';
import {
  renderInputItem,
  renderMenuItem,
  setHighlight,
} from '../helpers/helpers';
import {
  BoxStyled,
  IconBoxStyled,
  LabelStyled,
  VirtualizedListStyled,
} from '../Select.styles';

export interface BaseItem {
  id: number | string;
  title?: string;
}

export interface SelectMenuCommonProps<T> {
  itemType?: 'default' | 'hotel' | 'price';
  height?: string | number;
  width?: string | number;
  menuWidth?: string | number;
  items: T[] | undefined;
  label?: string;
  isShowLabel?: boolean;
  titleKey?: keyof T;
  value?: number | number[] | string | string[] | null;
  defaultValue?: T | T[] | null;
  isAutocomplete?: boolean;
  handleSearch?: (text: string) => void;
  searchKeys?: (keyof T)[];
  isLoading?: boolean;
  isListLoading?: boolean;
  isError?: boolean;
  helperText?: string;
  hasBorder?: boolean;
  sxBox?: SxProps;
  sxMenu?: SxProps;
  selectAllLabel?: string;
  iconStart?: ReactNode;
  placeholder?: ReactNode;
  reset?: boolean;
  listProps?: Record<string, unknown>;
  disabled?: boolean;
  emptyValue?: string;
}

type ConditionalSelectMenuProps<T> =
  | {
      onChange: (items: T[] | null) => void;
      multiple: true;
    }
  | {
      onChange: (item: T | null) => void;
      multiple?: false;
    };

type SelectMenuProps<T extends BaseItem> = SelectMenuCommonProps<T> &
  ConditionalSelectMenuProps<T>;

export interface RenderItemsProps<T extends BaseItem> {
  titleKey: SelectMenuCommonProps<T>['titleKey'];
  itemType: SelectMenuCommonProps<T>['itemType'];
  selected: T | T[] | null;
  item: T;
  index: number;
  isListLoading: boolean;
  handleClick: (item: T) => void;
  handleAllClick: VoidFunction;
}

/** Компонент по типу Select со встроенной виртуализацией,
 предназначен для рендера большого объема данных **/
export function SelectMenu<T extends BaseItem>({
  multiple = false,
  itemType = 'default',
  height = rem(56),
  width = 'auto',
  menuWidth = 'auto',
  items,
  label = '',
  isShowLabel = false,
  titleKey = 'title',
  onChange,
  value,
  isAutocomplete = false,
  handleSearch,
  searchKeys = ['id'],
  isLoading = false,
  isListLoading = false,
  isError = false,
  helperText = '',
  hasBorder = true,
  sxBox = {},
  sxMenu = {},
  selectAllLabel = '',
  iconStart,
  reset = false,
  defaultValue,
  listProps = {},
  disabled = false,
  emptyValue,
  placeholder,
  ...rest
}: SelectMenuProps<T>) {
  const isMobile = useMediaQuery(theme.breakpoints.down(BREAKPOINTS.MD));

  const [list, setList] = useState<T[]>(items ?? []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuSize, setMenuSize] = useState<number | string>(menuWidth);

  const [searchText, setSearchText] = useState<string>('');
  const [selected, setSelected] = useState<T | T[] | null>(
    defaultValue ?? null,
  );
  const [prevSelected, setPrevSelected] = useState<T | T[] | null>(selected);

  const itemHeight = useMemo(() => {
    switch (itemType) {
      case 'hotel':
      case 'price':
        return 48;

      default:
        return isMobile ? 48 : 36;
    }
  }, [isMobile, itemType]);

  const isNeedSearch = useMemo(() => {
    if (handleSearch) {
      return true;
    }

    if (items) {
      return isAutocomplete && items.length >= 5;
    }

    return false;
  }, [handleSearch, isAutocomplete, items]);

  const generalItem: T | null = useMemo(() => {
    if (selectAllLabel) {
      return { id: -1, [titleKey]: selectAllLabel } as unknown as T;
    }

    return null;
  }, [selectAllLabel, titleKey]);

  /** Отображаемый элемент(ы) в главном инпуте **/
  const inputSelected = useMemo(() => {
    if (multiple && isMobile) {
      if (prevSelected) {
        return prevSelected;
      }

      return generalItem;
    }

    return selected;
  }, [generalItem, isMobile, multiple, prevSelected, selected]);

  /** В какой момент рисуем лейбл **/
  const isNeedShowLabel = useMemo(() => {
    if (isShowLabel) {
      return true;
    }

    if (label) {
      return !selected && !inputSelected;
    }
  }, [isShowLabel, label, selected]);

  const filteredList: T[] = useMemo(() => {
    if (isNeedSearch) {
      const filteredData = list.filter(({ id }) =>
        Array.isArray(selected)
          ? !selected.find(({ id: selectedId }) => selectedId === id)
          : id !== selected?.id,
      );

      if (generalItem) {
        return [generalItem, ...filteredData];
      }

      return filteredData;
    }

    if (generalItem) {
      return [generalItem, ...list];
    }

    return list;
  }, [isNeedSearch, list, selected, generalItem]);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    if (!disabled && !isLoading) {
      setAnchorEl(event.currentTarget);

      /** Если не задана ширина меню вручную, то устанавливаем автоматически **/
      if (menuWidth === 'auto') {
        setMenuSize(event.currentTarget.clientWidth);
      }
    }
  };

  const handleClose = (isAutomatic = true) => {
    setAnchorEl(null);

    /** При мобильной версии с множественном выбором и по кнопке "Применить"
     * пробрасываем наружу элементы после закрытия **/
    if (isMobile && multiple && !isAutomatic) {
      if (Array.isArray(selected)) {
        onChange(selected as T & T[]);
      } else {
        onChange(null);
      }
    }

    /** При мобильной версии с множественным выбором и автоматическом закрытии
     * сбрасываем элементы на входные **/
    if (isMobile && multiple && isAutomatic) {
      setSelected(prevSelected ? prevSelected : generalItem);
    }

    /** При десктопной версии и множественном выборе
     * пробрасываем на ружу элементы только после закрытия **/
    if (!isMobile && multiple) {
      setPrevSelected(selected ?? generalItem);

      if (Array.isArray(selected)) {
        onChange(selected as T & T[]);
      } else {
        onChange(null);
      }

      if (!selected) {
        setSelected(generalItem);
      }
    }

    /** Если производился поиск, то после закрытия сбрасываем вводные **/
    if (searchText) {
      setList(items ?? []);
      setSearchText('');

      if (handleSearch) {
        handleSearch('');
      }
    }
  };

  /** Выбор всех элементов **/
  const handleAllClick = () => {
    setSelected(generalItem);
    setPrevSelected(generalItem);
    onChange(null as unknown as T & T[]);
    setAnchorEl(null);
  };

  /** Выбор элементов **/
  const handleItemClick = (item: T) => {
    if (anchorEl) {
      if (multiple) {
        /** Множественный выбор элементов **/
        const actualItems: T[] | null = Array.isArray(selected)
          ? selected.map(({ id }) => id).includes(item.id)
            ? selected.filter((data) => data.id !== item.id).length
              ? selected.filter((data) => data.id !== item.id)
              : null
            : [...selected, item]
          : [item];

        setSelected(actualItems);
      } else {
        /** Одиночный выбор элементов **/
        setSelected(item);
        onChange(item as T & T[]);

        if (isMobile) {
          handleClose();
        } else {
          handleClose();
        }
      }
    }
  };

  const handleOnSearch = (text: string) => {
    setSearchText(text);

    /** Внутренний поиск элементов **/
    if (!handleSearch) {
      const filteredList = items?.filter((item) =>
        searchKeys?.some((key) =>
          String(item[key]).toLowerCase().includes(text.toLowerCase()),
        ),
      );

      setList(filteredList ?? []);
    }
  };

  /** Кнопка применить доступна только для multiple значений,
   * тригерим onChange только после закрытия */
  const handleAcceptClick = () => {
    setPrevSelected(selected);
    handleClose(false);
  };

  /** Изменение отображаемых элементов **/
  const handleRangeChange = () => {
    if (searchText) {
      setHighlight(searchText);
    }
  };

  /** Проброс поиска наружу (для реализации через бэкенд) **/
  useEffect(() => {
    if (handleSearch) {
      handleSearch(searchText);
    }
  }, [searchText]);

  /** Выделение текста **/
  useEffect(() => {
    if (filteredList.length) {
      setTimeout(() => setHighlight(searchText));
    }
  }, [searchText, handleSearch, filteredList]);

  /** Обновление значения **/
  useLayoutEffect(() => {
    if (items?.length) {
      if (
        selectAllLabel &&
        Array.isArray(value) &&
        items.length === value.length
      ) {
        setSelected(generalItem);
      } else {
        const actualItems = items.filter(({ id }) =>
          Array.isArray(value)
            ? value.includes(id as number & string)
            : id === value,
        );

        setSelected(actualItems.length ? actualItems : null);
        setPrevSelected(actualItems.length ? actualItems : null);
      }
    }
  }, [value, isLoading]);

  /** Выбор значения по умолчанию **/
  useLayoutEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
      setPrevSelected(defaultValue);
    }
  }, [defaultValue]);

  /** При изменении списка сбрасываем значения **/
  useLayoutEffect(() => {
    if (reset && !defaultValue) {
      setSelected(multiple ? generalItem : null);
      onChange(null);

      if (isMobile) {
        setPrevSelected(multiple ? generalItem : null);
      }
    }
  }, [items, defaultValue, reset]);

  /** Обновление списка **/
  useLayoutEffect(() => {
    if (items) {
      setList(items);
    }
  }, [items]);

  return (
    <>
      <Box {...rest} width={width}>
        <BoxStyled
          height={height}
          onClick={handleOpen}
          hasBorder={hasBorder}
          isError={isError}
          isDisabled={disabled}
          sx={{
            padding: isShowLabel && selected ? rem(8, 16, 0) : rem(0, 16),
            ...sxBox,
          }}
        >
          <DataLoader
            width="80%"
            height={rem(12)}
            data={list}
            isLoading={Boolean(isLoading)}
          >
            {() => (
              <>
                {iconStart && (
                  <IconBoxStyled
                    isOpen={false}
                    isDisabled={disabled}
                    sx={{
                      ml: 0,
                      mr: rem(8),
                      marginTop: rem(isNeedShowLabel && selected ? -8 : 0),
                    }}
                  >
                    {iconStart}
                  </IconBoxStyled>
                )}

                {isNeedShowLabel && (
                  <LabelStyled
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    color={disabled ? 'basic.tertiary' : 'basic.secondary'}
                    variant={selected ? 'caption' : 'body2'}
                    isOpen={Boolean(selected)}
                  >
                    {label}
                  </LabelStyled>
                )}

                {placeholder && placeholder}

                {emptyValue && !selected && (
                  <Typography
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    color="basic.main"
                    variant="body2"
                  >
                    {emptyValue}
                  </Typography>
                )}

                {renderInputItem({
                  itemType,
                  titleKey,
                  selected: inputSelected,
                })}

                <IconBoxStyled
                  isOpen={Boolean(anchorEl)}
                  isDisabled={disabled}
                  sx={{ marginTop: rem(isNeedShowLabel && selected ? -8 : 0) }}
                >
                  <Dropdown width={rem(24)} height={rem(24)} />
                </IconBoxStyled>
              </>
            )}
          </DataLoader>
        </BoxStyled>

        {helperText && (
          <Typography
            data-cy="error-message"
            variant="footnote"
            color="basic.red.90"
            mt={rem(4)}
          >
            {helperText}
          </Typography>
        )}
      </Box>

      {!isMobile && (
        <Menu
          //Issue: https://github.com/mui/mui-x/issues/10389
          // slotProps={{ backdrop: { 'data-cy': 'select_backdrop' } as any }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => handleClose()}
          MenuListProps={listProps}
          PaperProps={{
            sx: {
              /** При закрытии убираем элемент из "дом дерева" при количестве элементов > 30,
               чтобы избежать блокировки других элементов(кнопки, инпуты) **/
              display:
                anchorEl || (items?.length && items.length < 30)
                  ? 'block'
                  : 'none',
              minWidth: width,
              width: menuSize,
              marginTop: rem(6),
              ...sxMenu,
            },
          }}
        >
          <SubHeaderInput
            handleOnSearch={handleOnSearch}
            isNeedSearch={isNeedSearch}
            searchText={searchText}
            selected={selected}
            itemType={itemType}
            titleKey={titleKey}
          />

          <VirtualizedListStyled
            atTopThreshold={5}
            atBottomThreshold={5}
            increaseViewportBy={800}
            style={{
              height: rem(
                Math.min(
                  1048,
                  window.innerHeight / 2,
                  filteredList.length * itemHeight,
                ),
              ),
            }}
            rangeChanged={handleRangeChange}
            data={filteredList}
            totalCount={filteredList.length}
            itemContent={(index, item) =>
              renderMenuItem({
                item: item as T,
                index,
                titleKey,
                selected,
                itemType,
                isListLoading,
                handleAllClick,
                handleClick: handleItemClick,
              })
            }
          />
        </Menu>
      )}

      {isMobile && (
        <SwipeableDrawer
          open={Boolean(anchorEl)}
          anchor="bottom"
          onClose={() => handleClose()}
          onOpen={() => null}
          SlideProps={{
            unmountOnExit: true,
          }}
        >
          <SubHeaderInput
            handleOnSearch={handleOnSearch}
            isNeedSearch={isNeedSearch}
            searchText={searchText}
            selected={selected}
            itemType={itemType}
            titleKey={titleKey}
          />

          <VirtualizedListStyled
            {...listProps}
            isMobile={isMobile}
            atTopThreshold={5}
            atBottomThreshold={5}
            increaseViewportBy={800}
            style={{
              height: isNeedSearch
                ? '100dvh'
                : rem(filteredList.length * itemHeight + (multiple ? 90 : 0)),
            }}
            rangeChanged={handleRangeChange}
            data={filteredList}
            totalCount={filteredList.length}
            itemContent={(index, item) =>
              renderMenuItem({
                item: item as T,
                index,
                titleKey,
                selected,
                itemType,
                isListLoading,
                handleAllClick,
                handleClick: handleItemClick,
              })
            }
            components={{
              List: forwardRef<HTMLDivElement, ListProps>(function getList(
                { style, children },
                ref,
              ) {
                let caclStyles = style;

                if (multiple) {
                  caclStyles = { ...style, height: '100%' };
                }

                return (
                  <div style={caclStyles} ref={ref}>
                    {children}

                    {multiple && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        sx={{
                          position: 'sticky',
                          display: 'flex',
                          alignItems: 'center',
                          height: rem(90),
                          bottom: 0,
                        }}
                      >
                        <ButtonBase
                          data-cy="apply_button"
                          size="large"
                          onClick={handleAcceptClick}
                          sx={{
                            position: 'fixed',
                            bottom: rem(20),
                            width: rem(280),
                          }}
                        >
                          Применить
                        </ButtonBase>
                      </Box>
                    )}
                  </div>
                );
              }),
            }}
          />
        </SwipeableDrawer>
      )}
    </>
  );
}
