import { paginationItemClasses, touchRippleClasses } from '@mui/material';
import { Components, Theme } from '@mui/material/styles';
import { Arrow } from '@otello/assets';
import { rem } from '@otello/helpers';

export function MuiPaginationItem({
  palette,
}: Theme): Components['MuiPaginationItem'] {
  return {
    defaultProps: {
      slots: {
        previous: ({ ...rest }) => (
          <Arrow
            {...rest}
            transform="rotate(180)"
            width={rem(24)}
            height={rem(24)}
          />
        ),
        next: ({ ...rest }) => (
          <Arrow {...rest} width={rem(24)} height={rem(24)} />
        ),
      },
    },

    styleOverrides: {
      root: {
        [`.${touchRippleClasses.root}`]: {
          display: 'none',
        },

        [`&.${paginationItemClasses.root}`]: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: palette.basic.gray['30'],
          color: palette.basic.secondary,
          width: rem(42),
          height: rem(40),
          fontSize: rem(16),
          border: 0,
          borderRadius: rem(12),
        },

        [`&:not(.${paginationItemClasses.ellipsis}):hover`]: {
          backgroundColor: palette.basic.gray['40'],
          color: palette.basic.secondary,
        },

        [`&.${paginationItemClasses.selected}`]: {
          backgroundColor: palette.basic.white['100'],
          color: palette.basic.green['90'],

          [`&:hover`]: {
            backgroundColor: palette.basic.white['100'],
            color: palette.basic.green['90'],
          },
        },
      },
    },
  };
}
