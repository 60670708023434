import { Box, BoxProps, MenuItem, MenuItemProps } from '@mui/material';
import { Checked } from '@otello/assets';
import { rem } from '@otello/helpers';
import { themePalette } from '@otello/theme';

import { ItemBoxStyled, TypographyStyled } from '../../Select.styles';
import { Item, SelectBaseProps } from '../../SelectBase/Select';

interface PriceItemProps<T extends Item> {
  item: T | T[];
  isActive?: boolean;
  isInput?: boolean;
  component?: BoxProps['component'];
}

export const PriceItem = <T extends Item>(
  props: PriceItemProps<T> & MenuItemProps & BoxProps & SelectBaseProps<T>,
) => {
  const {
    component,
    item,
    titleKey = 'title',
    isActive = false,
    isInput = false,
    sx,
  } = props;

  return (
    <ItemBoxStyled
      {...props}
      sx={{
        '&.Mui-selected': {
          background: 'unset',
        },
        ...sx,
      }}
      height={rem(48)}
      component={component ?? MenuItem}
    >
      <Box width="100%" display="grid">
        <TypographyStyled
          variant={!Array.isArray(item) && item.subtitle ? 'footnote' : 'body2'}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          className="highlight"
          color="basic.primary"
          sx={{ color: `${themePalette.basic.primary} !important` }}
        >
          {Array.isArray(item)
            ? item.map((element) => element[titleKey] as string).join(', ')
            : (item[titleKey] as string) ?? ''}
        </TypographyStyled>

        {!Array.isArray(item) && item.subtitle && (
          <TypographyStyled
            variant="p4"
            sx={{ display: 'block' }}
            color="basic.secondary"
            className={isInput ? '' : 'highlight'}
          >
            {item.subtitle}
          </TypographyStyled>
        )}
      </Box>

      {isActive && !isInput && (
        <Box
          display="flex"
          alignItems="center"
          color="basic.green.90"
          ml={rem(16)}
        >
          <Checked width={rem(20)} height={rem(20)} />
        </Box>
      )}
    </ItemBoxStyled>
  );
};
