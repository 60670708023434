import { styled, Select, Typography, ListSubheader, Box } from '@mui/material';
import { Dropdown } from '@otello/assets';
import { rem } from '@otello/helpers';
import { themePalette } from '@otello/theme';
import { Virtuoso as VirtualizedList } from 'react-virtuoso';

export const SelectStyled = styled(Select, {
  shouldForwardProp: (props) =>
    props !== 'backgroundColor' &&
    props !== 'isBorder' &&
    props !== 'handleChange',
})<{
  backgroundColor: string;
  isBorder: boolean;
}>(({ theme: { palette }, backgroundColor, isBorder }) => ({
  '&.MuiInputBase-root': {
    backgroundColor: backgroundColor,
    borderRadius: rem(12),

    '& > svg': {
      top: 'unset',
    },
  },

  fieldset: {
    border: !isBorder && '0',
  },

  '&:hover fieldset': {
    borderColor: `${palette.basic.gray['70']} !important`,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: palette.basic.gray['50'],
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: isBorder && `${rem(1)} solid ${palette.basic.gray['70']}`,
  },

  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: palette.basic.red['80'],
  },
}));

export const TypographyStyled = styled(Typography)(() => ({
  overflow: 'hidden',
  display: 'block',
  textOverflow: 'ellipsis',
}));

export const DropdownStyled = styled(Dropdown)(() => ({
  width: rem(24),
  height: rem(24),
  right: `${rem(8)} !important`,
}));

export const ListSubheaderStyled = styled(ListSubheader, {
  shouldForwardProp: (props) => props !== 'hasActive',
})<{
  hasActive: boolean;
}>(({ hasActive }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: rem(16, 16, hasActive ? 0 : 16, 16),
  marginBottom: rem(hasActive ? 8 : 0),
}));

export const ItemBoxStyled = styled(Box, {
  shouldForwardProp: (props) =>
    props !== 'titleKey' &&
    props !== 'IconRenderValue' &&
    props !== 'isActive' &&
    props !== 'isInput',
})(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: rem(8, 16),
}));

export const BoxStyled = styled(Box, {
  shouldForwardProp: (props) =>
    props !== 'hasBorder' && props !== 'isError' && props !== 'isDisabled',
})<{ hasBorder: boolean; isError: boolean; isDisabled: boolean }>(
  ({ theme: { palette }, hasBorder, isError, isDisabled }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: rem(12),
    backgroundColor: isDisabled ? themePalette.basic.gray['20'] : 'inherit',
    border: hasBorder
      ? `1px solid ${
          isError
            ? palette.basic.red['100']
            : isDisabled
            ? palette.basic.gray['50']
            : palette.basic.gray['70']
        }`
      : 'none',

    '&:hover': {
      cursor: 'pointer',

      '& svg': {
        color: palette.basic.gray['60'],
      },
    },
  }),
);

export const LabelStyled = styled(Typography, {
  shouldForwardProp: (props) => props !== 'isOpen',
})<{ isOpen: boolean }>(({ isOpen = false }) => ({
  position: isOpen ? 'absolute' : 'static',
  left: rem(isOpen ? 16 : 0),
  top: rem(isOpen ? 8 : 0),
}));

export const IconBoxStyled = styled(Box, {
  shouldForwardProp: (props) => props !== 'isOpen' && props !== 'isDisabled',
})<{ isOpen: boolean; isDisabled: boolean }>(
  ({ theme: { palette }, isOpen, isDisabled }) => ({
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: rem(24),
    height: rem(24),
    color: isDisabled ? palette.basic.tertiary : palette.basic.secondary,

    svg: {
      transform: `rotate(${isOpen ? 180 : 0}deg)`,
    },
  }),
);

export const VirtualizedListStyled = styled(VirtualizedList, {
  shouldForwardProp: (props) => props !== 'isMobile',
})<{ isMobile?: boolean }>(({ isMobile = false }) => ({
  '&::-webkit-scrollbar': {
    display: isMobile ? 'none' : 'static',
  },
}));
