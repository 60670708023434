import {
  Box,
  BoxProps,
  MenuItem,
  MenuItemProps,
  useMediaQuery,
} from '@mui/material';
import { Checked } from '@otello/assets';
import { rem } from '@otello/helpers';
import { BREAKPOINTS, theme } from '@otello/theme';

import { ItemBoxStyled, TypographyStyled } from '../../Select.styles';
import { Item, SelectBaseProps } from '../../SelectBase/Select';

interface RootItemProps<T extends Item> {
  item: T | T[];
  isActive?: boolean;
  isInput?: boolean;
  component?: BoxProps['component'];
}

export const RootItem = <T extends Item>(
  props: RootItemProps<T> & MenuItemProps & BoxProps & SelectBaseProps<T>,
) => {
  const isMobile = useMediaQuery(theme.breakpoints.down(BREAKPOINTS.MD));

  const {
    IconRenderValue,
    component,
    item,
    titleKey = 'title',
    isActive = false,
    isInput = false,
    sx,
  } = props;

  return (
    <ItemBoxStyled
      {...props}
      sx={sx}
      component={component ?? MenuItem}
      height={isMobile ? rem(48) : rem(36)}
    >
      {IconRenderValue && (
        <Box
          display="flex"
          alignItems="center"
          width={rem(24)}
          height={rem(24)}
          color={isActive ? 'basic.green.90' : 'basic.tertiary'}
          mr={rem(6)}
        >
          {IconRenderValue}
        </Box>
      )}
      <Box width="100%" display="grid">
        <TypographyStyled
          variant={!Array.isArray(item) && item.subtitle ? 'footnote' : 'body2'}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          className={isInput ? '' : 'highlight'}
          color={
            (!Array.isArray(item) && item.subtitle) || isActive
              ? 'basic.primary'
              : 'basic.secondary'
          }
        >
          {Array.isArray(item)
            ? item.map((element) => element[titleKey] as string).join(', ')
            : (item[titleKey] as string) ?? ''}
        </TypographyStyled>
        {!Array.isArray(item) && item.subtitle && (
          <TypographyStyled
            variant="p4"
            sx={{ display: 'block' }}
            color="basic.secondary"
            className={isInput ? '' : 'highlight'}
          >
            {item.subtitle}
          </TypographyStyled>
        )}
      </Box>

      {isActive && !isInput && (
        <Box
          display="flex"
          alignItems="center"
          color="basic.green.90"
          ml={isMobile ? 'auto' : rem(16)}
        >
          <Checked width={rem(24)} height={rem(24)} />
        </Box>
      )}
    </ItemBoxStyled>
  );
};
