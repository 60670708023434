import { rem } from '@otello/helpers';
import { themePalette } from '@otello/theme';

import { HotelItem } from '../components/HotelItem/HotelItem';
import { LoadingItem } from '../components/LoadingItem/LoadingItem';
import { PriceItem } from '../components/PriceItem/PriceItem';
import { RootItem } from '../components/RootItem/RootItem';
import { BaseItem, RenderItemsProps } from '../SelectMenu/SelectMenu';

export const setHighlight = (searchText: string) => {
  const elements = document.querySelectorAll('.highlight');

  const regExp = new RegExp(
    searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'),
    'gi',
  );

  elements.forEach((element) => {
    if (element.textContent) {
      element.innerHTML = element.textContent.replace(
        regExp,
        `<span style="color: ${themePalette.basic.green['90']}">$&</span>`,
      );
    }
  });
};

// Рендер главное итема в инпуте
export function renderInputItem<T extends BaseItem>({
  selected,
  titleKey,
  itemType,
}: Pick<
  RenderItemsProps<T>,
  'selected' | 'titleKey' | 'itemType'
>): JSX.Element {
  if (selected) {
    switch (itemType) {
      case 'hotel':
        return (
          <HotelItem
            isActive
            isInput
            component="div"
            item={Array.isArray(selected) ? selected[0] : selected}
            titleKey={titleKey}
            sx={{ pr: 0, pl: 0 }}
          />
        );

      case 'price':
        return (
          <PriceItem
            isActive
            isInput
            component="div"
            item={selected}
            titleKey={titleKey}
            sx={{ pr: 0, pl: 0 }}
          />
        );

      case 'default':
        return (
          <RootItem
            isActive
            isInput
            component="div"
            item={selected}
            titleKey={titleKey}
            sx={{ pr: 0, pl: 0 }}
          />
        );

      default:
        return <></>;
    }
  }

  return <></>;
}

// Рендер итема в заголовке дропдауна
export function renderHeaderItem<T extends BaseItem>({
  selected,
  itemType,
  titleKey,
}: Pick<RenderItemsProps<T>, 'selected' | 'titleKey' | 'itemType'>):
  | JSX.Element
  | undefined {
  switch (itemType) {
    case 'hotel':
      return (
        <HotelItem
          isActive
          component="div"
          item={(Array.isArray(selected) ? selected[0] : selected) as T}
          titleKey={titleKey}
          value={JSON.stringify(selected)}
          sx={{ mt: rem(8), mb: rem(8), pr: 0, pl: 0 }}
        />
      );

    case 'default':
      return (
        <RootItem
          isActive
          component="div"
          item={(Array.isArray(selected) ? selected[0] : selected) as T}
          titleKey={titleKey}
          value={JSON.stringify(selected)}
          sx={{ mt: rem(8), mb: rem(8), pr: 0, pl: 0 }}
        />
      );

    default:
      return <></>;
  }
}

// Рендер меню итема
export function renderMenuItem<T extends BaseItem>({
  titleKey,
  item,
  itemType,
  selected,
  index,
  isListLoading,
  handleClick,
  handleAllClick,
}: RenderItemsProps<T>): JSX.Element {
  if (isListLoading) {
    return <LoadingItem key={index} />;
  }

  switch (itemType) {
    case 'hotel':
      return (
        <HotelItem
          key={item.id}
          item={item}
          titleKey={titleKey}
          isActive={
            Array.isArray(selected)
              ? selected.map((act) => act.id).includes(item.id)
              : item.id === selected?.id
          }
          onClick={() =>
            item.id === -1 ? handleAllClick() : handleClick(item)
          }
        />
      );

    case 'price':
      return (
        <PriceItem
          key={item.id}
          item={item}
          titleKey={titleKey}
          onClick={() =>
            item.id === -1 ? handleAllClick() : handleClick(item)
          }
          isActive={
            Array.isArray(selected)
              ? selected.map((act) => act.id).includes(item.id)
              : item.id === selected?.id
          }
        />
      );

    case 'default':
      return (
        <RootItem
          key={item.id}
          item={item}
          titleKey={titleKey}
          onClick={() =>
            item.id === -1 ? handleAllClick() : handleClick(item)
          }
          isActive={
            Array.isArray(selected)
              ? selected.map((act) => act.id).includes(item.id)
              : item.id === selected?.id
          }
        />
      );

    default:
      return <></>;
  }
}
