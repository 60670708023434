import { FC, ReactNode } from 'react';

import { Button, ButtonProps, LinkProps } from '@mui/material';
import { rem } from '@otello/helpers';

import { Loader } from '../../Loader/Loader';

interface ButtonBaseProps extends ButtonProps {
  target?: LinkProps['target'];
  rel?: LinkProps['rel'];
  variant?: 'contained' | 'text';
  color?: ButtonProps['color'];
  full?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  children?: string | ReactNode;
}

export const ButtonBase: FC<ButtonBaseProps> = ({
  variant = 'contained',
  full = true,
  color = 'primary',
  disabled = false,
  startIcon,
  endIcon,
  loading = false,
  children,
  sx: additionalSx,
  ...rest
}) => {
  return (
    <Button
      disabled={loading || disabled}
      color={color}
      fullWidth={full}
      size="medium"
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        '& .MuiButton-startIcon': {
          marginRight: children ? rem(8) : 'unset',
          marginLeft: 0,
        },
        fontWeight: 600,
        ...additionalSx,
      }}
      {...rest}
    >
      {loading ? <Loader /> : children}
    </Button>
  );
};
