import { Box, MenuItem, generateUtilityClasses, styled } from '@mui/material';
import { rem } from '@otello/helpers';

export const classes = generateUtilityClasses('MenuBase', [
  'itemBase',
  'itemBox',
]);

export const MenuItemBase = styled(MenuItem, {
  label: classes.itemBase,
  shouldForwardProp: (props) => props !== 'svgColor',
})<{ svgColor?: string }>(({ theme: { palette }, svgColor }) => ({
  padding: rem(0, 20),
  color: palette.basic.tertiary,

  '&:hover': {
    backgroundColor: 'unset',

    p: {
      color: palette.basic.primary,
    },

    svg: {
      color: svgColor ?? palette.basic.green['90'],
    },
  },
}));

export const MenuItemBox = styled(Box, {
  label: classes.itemBox,
})(() => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: rem(170),
  height: rem(40),
  padding: 0,
}));
