import { Components, Theme } from '@mui/material/styles';
import { media, rem } from '@otello/helpers';

import { BREAKPOINTS } from '../../breakpoints';

export function MuiButton({
  palette,
  breakpoints,
}: Theme): Components['MuiButton'] {
  return {
    defaultProps: {
      disableElevation: true,
      disableRipple: true,
      disableTouchRipple: true,
    },

    styleOverrides: {
      root: {
        fontSize: rem(16),
        lineHeight: rem(20),
        borderRadius: rem(12),
        boxShadow: 'none',
        minWidth: 0,
        textTransform: 'none',
        letterSpacing: '-0.015em',

        [breakpoints.down(BREAKPOINTS.MD)]: {
          textAlign: 'start',
        },

        [media.hover]: {
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'unset',
          },
        },
      },

      sizeSmall: {
        height: rem(40),
        padding: rem(8, 12),
      },

      sizeMedium: {
        height: rem(48),
        padding: rem(16, 30),
      },

      sizeLarge: {
        height: rem(56),
        padding: rem(20, 30),
      },

      startIcon: {
        marginRight: rem(8),
      },

      // variant = 'contained'
      containedPrimary: {
        backgroundColor: palette.basic.green['90'],
        color: palette.basic.white['100'],

        [media.hover]: {
          '&:hover': {
            backgroundColor: palette.basic.green['100'],
            boxShadow: 'none',
          },
        },

        '&:hover': {
          backgroundColor: palette.basic.green['100'],
          boxShadow: 'none',
        },

        '&:active': {
          backgroundColor: palette.basic.green['80'],
        },

        '&.Mui-disabled': {
          backgroundColor: palette.basic.gray['30'],
          color: palette.basic.tertiary,
        },
      },

      containedSecondary: {
        backgroundColor: palette.basic.gray['30'],
        color: palette.basic.primary,
        borderColor: palette.basic.gray['40'],

        [media.hover]: {
          '&:hover': {
            backgroundColor: palette.basic.gray['40'],
            boxShadow: 'none',
          },
        },

        '&:hover': {
          backgroundColor: palette.basic.gray['40'],
          boxShadow: 'none',
        },

        '&:active': {
          border: `1px solid ${palette.basic.gray['60']}`,
        },

        '&.Mui-disabled': {
          backgroundColor: palette.basic.gray['30'],
          color: palette.basic.tertiary,
        },
      },

      containedError: {
        backgroundColor: palette.basic.red['80'],
        color: palette.basic.white['100'],

        [media.hover]: {
          '&:hover': {
            backgroundColor: palette.basic.red['90'],
            boxShadow: 'none',
          },
        },

        '&:hover': {
          backgroundColor: palette.basic.red['90'],
          boxShadow: 'none',
        },

        '&:active': {
          backgroundColor: palette.basic.red['80'],
        },

        '&.Mui-disabled': {
          backgroundColor: palette.basic.gray['30'],
          color: palette.basic.tertiary,
        },
      },

      containedSurface: {
        backgroundColor: palette.basic.white['100'],
        color: palette.basic.tertiary,

        [media.hover]: {
          '&:hover': {
            backgroundColor: palette.basic.gray['20'],
            color: palette.basic.white['100'],
            boxShadow: 'none',
          },
        },

        '&:hover': {
          backgroundColor: palette.basic.green['90'],
          boxShadow: 'none',
        },

        '&:active': {
          backgroundColor: palette.basic.green['100'],
        },

        '&.Mui-disabled': {
          backgroundColor: palette.basic.white['100'],
          color: palette.basic.tertiary,
        },
      },

      // variant = 'outlined'
      outlinedPrimary: {},

      // variant = 'text'
      text: {
        fontWeight: `400 !important`,
      },

      textPrimary: {
        color: palette.basic.green['80'],

        [media.hover]: {
          '&:hover': {
            color: palette.basic.green['90'],
            boxShadow: 'none',
          },
        },

        '&:active': {
          color: palette.basic.green['100'],
        },

        '&.Mui-disabled': {
          color: palette.basic.gray['30'],
        },
      },

      textSecondary: {
        color: palette.basic.secondary,

        [media.hover]: {
          '&:hover': {
            color: palette.basic.green['80'],
            boxShadow: 'none',
          },
        },

        '&:active': {
          color: palette.basic.green['100'],
        },

        '&.Mui-disabled': {
          color: palette.basic.gray['30'],
        },
      },

      textError: {
        color: palette.basic.red['90'],

        [media.hover]: {
          '&:hover': {
            color: palette.basic.red['90'],
            boxShadow: 'none',
          },
        },

        '&:active': {
          color: palette.basic.red['90'],
        },

        '&.Mui-disabled': {
          color: palette.basic.gray['30'],
        },
      },
    },
  };
}
