import { generateUtilityClasses, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { rem } from '@otello/helpers';
import { BREAKPOINTS, marginRoot } from '@otello/theme';

export const classes = generateUtilityClasses('Main-Layout', ['root']);

export const Root = styled(Box, {
  label: classes.root,
})(({ theme: { palette, breakpoints } }) => ({
  flex: '1 0 auto',
  display: 'flex',
  justifyContent: 'center',
  background: palette.basic.gray['20'],

  [breakpoints.up(BREAKPOINTS.MD)]: {
    padding: rem(24, marginRoot),
  },
}));
